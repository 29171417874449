import React from "react";
import { useState } from "react";
import { money, coins } from "../coins/coins";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { TbDeviceMobileDown } from "react-icons/tb";
import Buy from "./Buy";
import Sell from "./Sell";
const BuySell = () => {
  const [buy, setBuy] = useState(true);
  const [sell, setSell] = useState(false);

  return (
    <div className="mx-2">
      <div className="flex items-center gap-3 text-lg px-2">
        <button
          onClick={() => {
            setBuy(true);
            setSell(false);
          }}
          className={`${buy ? "text-[#FFFF]" : "text-[#FFFF]/50"} duration-100`}
        >
          Buy
        </button>
        <button
          onClick={() => {
            setSell(true);
            setBuy(false);
          }}
          className={`${
            sell ? "text-[#FFFF]" : "text-[#FFFF]/50"
          } duration-100`}
        >
          Sell
        </button>
      </div>
      <div className="mt-5">
        {buy && <Buy />}
        {sell && <Sell />}
      </div>
    </div>
  );
};

export default BuySell;
