import { useState } from "react";
import "./App.css";
import Bridge from "./components/Bridge";
import BuySell from "./components/BuySell";
import Swap from "./components/Swap";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FaChevronDown } from "react-icons/fa6";
import Exchange from "./components/Exchange";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import GiveAddress from "./GiveAddress";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/giveaddress" element={<GiveAddress />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
