import React from "react";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { category, coins } from "../coins/coins";
import { useState } from "react";
const SelectedCoin = ({ setOpen, setPay, setRecive, data }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryShow, setCategoryShow] = useState([]);

  function handleChange(e) {
    const value = e.target.value.toLowerCase();

    if (value === "") {
      return setCategoryShow([]);
    }

    const newCoins = coins.filter((coin) =>
      coin.name.toLowerCase().includes(value)
    );

    console.log(newCoins);

    setCategoryShow(newCoins);
  }
  return (
    <div className="bg-[#222] h-full px-2">
      <div className="grid grid-cols-3 p-3">
        <div></div>
        <div className="flex justify-center text-xl">{data}</div>
        <button className="flex justify-end" onClick={() => setOpen(false)}>
          <IoMdClose size={25} />
        </button>
      </div>
      <div className=" mt-2 text-slate-300">
        <div className=" relative">
          <input
            type="text"
            onChange={handleChange}
            placeholder="Search "
            className="w-full bg-[#444]  pl-[10%] p-1 text-md  outline-none rounded-md"
          />
          <FiSearch className=" absolute left-1 top-[4px] text-2xl" />
        </div>
      </div>

      <div className="scroll-cate overflow-x-auto flex gap-3 w-full px-2 py-2 text-[12px]">
        <button
          onClick={() => {
            setSelectedCategory("");
            setCategoryShow([]);
          }}
          className={`${
            selectedCategory === "" ? "bg-indigo-500 text-white " : "border"
          } border-indigo-500/50 whitespace-nowrap  px-4 pt-1 pb-[6px]  duration-150 rounded-2xl`}
        >
          All
        </button>
        {category.map((e, idx) => (
          <button
            className={`${
              selectedCategory === e.name
                ? "bg-indigo-500 text-white"
                : "border"
            } border-indigo-500/50 block whitespace-nowrap  px-4 pt-1 pb-[6px]  duration-150 rounded-2xl  `}
            onClick={() => {
              setSelectedCategory(e.name);
              setCategoryShow(e.coins);
            }}
          >
            {e.name}
          </button>
        ))}
      </div>
      <div className="flex flex-col gap-1 mt-2 overflow-y-scroll  h-full pb-[180px]">
        <span>In Wallet</span>
        {categoryShow.length
          ? categoryShow.map((e, idx) => (
              <button
                onClick={() => {
                  if (data === "Pay") {
                    setPay(e);
                    setOpen(false);
                  } else {
                    setRecive(e);
                    setOpen(false);
                  }
                }}
                className="bg-[#444] flex items-center gap-2 justify-start p-2 rounded-md"
              >
                <img
                  className="w-[35px] h-[35px] rounded-full"
                  src={e.src}
                  alt=""
                />
                <span>{e.name}</span>
              </button>
            ))
          : coins.map((e, idx) => (
              <button
                onClick={() => {
                  if (data === "Pay") {
                    setPay(e);
                    setOpen(false);
                  } else {
                    setRecive(e);
                    setOpen(false);
                  }
                }}
                className="bg-[#444] flex items-center gap-2 justify-start p-2 rounded-md"
              >
                <img
                  className="w-[35px] h-[35px] rounded-full"
                  src={e.src}
                  alt=""
                />
                <span>{e.name}</span>
              </button>
            ))}
      </div>
    </div>
  );
};

export default SelectedCoin;
