import React from "react";
import { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { LuArrowUpDown } from "react-icons/lu";
import SelectedCoin from "./SelectedCoin";
import { coins } from "../coins/coins";
const Bridge = () => {
  const nonselected = { name: "Selected Coin", src: "/safepal.png" };
  const [pay, setPay] = useState(nonselected);
  const [recive, setRecive] = useState(nonselected);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  return (
    <div className="mx-2">
      <div className="bg-[#222] flex justify-between items-center p-3 rounded-md">
        <button
          onClick={() => {
            setOpen(true);
            setData("Pay");
          }}
          className="flex gap-3 items-center"
        >
          <img className="w-[30px]" src={pay.src} alt="" />
          <span>{pay.name}</span>
        </button>
        <div className="p-1 text-[#FFFF]/60">
          <FaChevronDown size={14} />
        </div>
      </div>
      <div className="mt-5 relative">
        <div className="bg-[#222] flex flex-col items-start justify-center gap-3 p-3 rounded-md mb-1">
          <span className="text-[14px]">Pay</span>
          <button
            onClick={() => {
              setOpen(true);
              setData("Pay");
            }}
            className="flex gap-3 items-center"
          >
            <img className="w-[30px]" src={pay.src} alt="" />
            <span>{pay.name}</span>
            <div className="p-1 text-[#FFFF]/60">
              <FaChevronDown size={14} />
            </div>
          </button>
        </div>
        <div className="bg-[#222] flex flex-col items-start justify-center gap-3 p-3 rounded-md mb-2">
          <span className="text-[14px]">Receive</span>
          <button
            onClick={() => {
              setOpen(true);
              setData("Recive");
            }}
            className="flex gap-3 items-center"
          >
            <img className="w-[30px]" src={recive.src} alt="" />
            <span>{recive.name}</span>
            <div className="p-1 text-[#FFFF]/60">
              <FaChevronDown size={14} />
            </div>
          </button>
        </div>
        <button
          onClick={() => {
            setPay(recive);
            setRecive(pay);
          }}
          className=" absolute top-[38%] right-[6%] z-10 bg-indigo-500 rounded-full p-3"
        >
          <LuArrowUpDown size={25} />
        </button>
      </div>
      <div className="bg-[#222] flex justify-between items-center p-3 rounded-md mt-3">
        <div className="">Amount</div>
        <div className=" text-[#FFFF]/60 text-[20px]">0</div>
      </div>
      <button className="w-full bg-[#333] rounded-md py-2 mt-3">BRIDGE</button>
      <div
        className={`${
          open ? "bottom-0 top-0 h-[800px]" : "bottom-[-30px] top-[110%] h-[0px]"
        } absolute overflow-y-hidden left-0 right-0  z-20 duration-300`}
      >
        <SelectedCoin
          setOpen={setOpen}
          setRecive={setRecive}
          setPay={setPay}
          data={data}
        />
      </div>
    </div>
  );
};

export default Bridge;
