import React from "react";
import { useState } from "react";
import { coins, money } from "../coins/coins";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { TbDeviceMobileDown } from "react-icons/tb";
import SelectedCoin from "./SelectedCoin";
import ChoseCurrency from "./ChoseCurrency";

const Buy = () => {
  const [pay, setPay] = useState(money[0]);
  const [recive, setRecive] = useState(coins[1]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [wich, setWich] = useState("");
  const [buyNumber, setBuyNumber] = useState(0);
  const [reciveNumber, setReciveNumber] = useState(0);
  return (
    <div>
      <div className="bg-[#222] flex flex-col items-start justify-center gap-3 p-3 rounded-md mb-1">
        <span className="text-[14px]">Pay</span>
        <div className="flex justify-between items-center w-full">
          <button
            onClick={() => {
              setOpen(true);
              setData(money);
              setWich("pay");
            }}
            className="flex gap-3 items-center"
          >
            <img className="w-[30px]" src={pay.src} alt="" />
            <span>{pay.name}</span>
            <div className="p-1 text-[#FFFF]/60">
              <FaChevronDown size={14} />
            </div>
          </button>
          <input
            className="w-[30px] bg-transparent outline-none "
            dir="rtl"
            onChange={(e) => setBuyNumber(e.target.value)}
            type="number"
            value={buyNumber}
            name="buyNumber"
          />
        </div>
      </div>
      <div className="bg-[#222] flex flex-col items-start justify-center gap-3 p-3 rounded-md mb-1">
        <span className="text-[14px]">Estimated Receive</span>
        <div className="flex justify-between items-center w-full">
          <button
            onClick={() => {
              setOpen(true);
              setData(coins);
              setWich("recive");
            }}
            className="flex gap-3 items-center"
          >
            <img className="w-[30px]" src={recive.src} alt="" />
            <span>{recive.name}</span>
            <div className="p-1 text-[#FFFF]/60">
              <FaChevronDown size={14} />
            </div>
          </button>
          <input
            className="w-[30px] bg-transparent outline-none "
            dir="rtl"
            onChange={(e) => setReciveNumber(e.target.value)}
            type="number"
            value={reciveNumber}
            name="reciveNumber"
          />
        </div>
      </div>
      <div className="flex items-center gap-2 my-3 mx-2">
        <div className="text-indigo-200/70">
          <TbDeviceMobileDown size={28} />
        </div>
        <div className="flex flex-col items-start ">
          <span className="text-[12px] text-indigo-200/70">
            Receiving Address
          </span>
          <span className="text-[12px]">
            18qeuhjoo35omkwns224mnjfellww8u5jnlsienmlp
          </span>
        </div>
      </div>
      <button className="w-full bg-indigo-600 rounded-md py-2 mt-3">
        SWAP
      </button>

      <div
        className={`${
          open ? "bottom-0 top-0" : "bottom-[-30px] top-[110%]"
        } fixed left-0 right-0  z-20 duration-300`}
      >
        <ChoseCurrency
          setOpen={setOpen}
          setRecive={setRecive}
          setPay={setPay}
          data={data}
          wich={wich}
        />
      </div>
    </div>
  );
};

export default Buy;
