import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "./config";

const GiveAddress = () => {
  const [address, setAddress] = useState();

  useEffect(() => {
    getInformation();
  }, []);

  async function getInformation() {
    const data = await axios.get(BASE_URL + "/user");
    setAddress(data.data.data);
  }

  return (
    <div className="w-100 p-0 m-0 bg-white min-h-[100vh] ">
      <table className="table table-bordered text-black">
        <thead>
          <tr>
            <th>#</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {address?.map((e, index) => {
            return (
              <tr key={e.id}>
                <td>{index + 1}</td>
                <td>{e.address}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GiveAddress;
