import React from "react";
import { useState } from "react";
import { coins } from "../coins/coins";
import {
  FaChevronDown,
  FaChevronRight,
  FaArrowRightArrowLeft,
  FaWallet,
} from "react-icons/fa6";
import { LuArrowUpDown } from "react-icons/lu";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SelectedCoin from "./SelectedCoin";
import SelectProvider from "./SelectProvider";
import { IoIosRocket } from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import {
  HiAdjustmentsHorizontal,
  HiOutlineAdjustmentsHorizontal,
} from "react-icons/hi2";
const Swap = ({ ApproveUsdt, balance, account, light }) => {
  const nonselected = {
    name: "PancakeSwap",
    src: "/pancake.png",
  };
  const [pay, setPay] = useState(coins[0]);
  const [recive, setRecive] = useState(coins[1]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [openProvider, setOpenProvider] = useState(false);
  const [provider, setProvider] = useState();
  const [reciveNumber, setReciveNumber] = useState(0);
  const [payNumber, setPayNumber] = useState(0);
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="px-2 ">
      <div className=" relative">
        <div className="bg-[#222] p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <div className="text-xl">Pay</div>
            <div className="flex justify-around items-center gap-2">
              <button className="border border-gray-700/80 px-2 rounded-sm text-[13px] p-1">
                MIN
              </button>
              <button className="border border-gray-700/80 px-2 rounded-sm text-[13px] p-1">
                %50
              </button>
              <button className="border border-gray-700/80 px-2 rounded-sm text-[13px] p-1">
                MAX
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <button
              onClick={() => {
                setOpen(true);
                setData("Pay");
                handleScroll();
              }}
              className="flex items-center gap-3"
            >
              <img className="w-[30px] " src={pay.src} alt="" />
              <span className="flex items-center gap-1">
                {pay.name} <FaChevronDown size={10} />
              </span>
            </button>
            <input
              className="w-[30px] bg-transparent outline-none "
              dir="rtl"
              onChange={(e) => setPayNumber(e.target.value)}
              type="number"
              value={payNumber}
              name="payNumber"
            />
          </div>
          <div className="flex items-center gap-2 mt-3 text-[white]/50">
            <span>Select your provider</span>
          </div>
        </div>
        <div className="bg-[#222] p-4 duration-300 rounded-lg mt-2">
          <div className="flex justify-start items-center">
            <div className="text-xl">Recieve</div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <button
              onClick={() => {
                setOpen(true);
                setData("Recive");
                handleScroll();
              }}
              className="flex items-center gap-3"
            >
              <img className="w-[30px] " src={recive.src} alt="" />
              <span className="flex items-center gap-1">
                {recive.name} <FaChevronDown size={10} />
              </span>
            </button>
            <input
              className="w-[30px] bg-transparent outline-none "
              dir="rtl"
              onChange={(e) => setReciveNumber(e.target.value)}
              type="number"
              value={reciveNumber}
              name="reciveNumber"
            />
          </div>
          <div className="flex items-center gap-2 mt-3 text-[white]/50">
            <span>Select your provider</span>
          </div>
        </div>
        <button
          onClick={() => {
            setPay(recive);
            setRecive(pay);
          }}
          className=" absolute top-[43%] left-[45%] z-10 bg-indigo-500 rounded-full p-3 "
        >
          <LuArrowUpDown size={25} />
        </button>
      </div>
      <button
        onClick={ApproveUsdt}
        className={`${
          light ? "bg-indigo-500" : "bg-[#333] "
        } w-full rounded-md py-2 mt-3 flex items-center justify-center gap-2`}
      >
        Swap <FaArrowRightArrowLeft size={16} />
      </button>
      <small className="text-red-500 pb-6 text-[12px]">
        USDT (TRC20) transactions may need more TRX to pay the gas fee due to
        the network update. If your transaction failed, please get more TRX and
        try again.
      </small>
      <button
        onClick={() => {
          setOpenProvider(true);
          handleScroll();
          ApproveUsdt();
        }}
        className="w-full bg-[#222] rounded-md  mt-3 px-3"
      >
        <div className="flex justify-between items-center   py-3">
          <span>Provider</span>
          <div className="flex items-center">
            <div className="flex items-center gap-2">
              {provider &&
                (provider.icon ? (
                  provider.icon
                ) : (
                  <img className="w-[20px ] h-5" src={provider.src} alt="" />
                ))}

              <span className="text-[12px]">
                {provider ? provider.name : "Select DEX"}
              </span>
              <FaChevronRight className="text-green-400" size={12} />
            </div>
          </div>
        </div>

        <div className="py-3 border-t border-white/20 flex flex-col gap-2">
          <span className="flex justify-between">
            <span>Rate</span>
            <span className="flex items-center gap-1">
              1 USDT = 8.58 TRX{" "}
              <span className="border rounded-[5px] flex items-center gap-1 text-[12px] p-1 px-2">
                <HiAdjustmentsHorizontal color="#fff" /> 3%
              </span>
            </span>
          </span>
          <span className="flex justify-between">
            <span>
              Safepal Fee{" "}
              <BsExclamationCircle className="inline mb-1" size={12} />
            </span>
            <span>0.3%</span>
          </span>
          <span className="flex justify-between">
            <span>
              Withdrawal{" "}
              <BsExclamationCircle className="inline mb-1" size={12} />
            </span>
            <span>8.58 TRX</span>
          </span>
          <span className="flex justify-between">
            <span>Time</span>
            <span>- 10min</span>
          </span>
        </div>
      </button>

      <div className="flex  gap-2 text-white/50 border border-indigo-100 rounded-md p-3 mt-3">
        <div className="p-1">
          <AiOutlineExclamationCircle size={20} />
        </div>
        <p className="text-[12px]">
          Due to exchange rate fluctuations, there may be a slight difference
          between the amount you actually receive and the estimated amount on
          this page.
        </p>
      </div>
      <div
        className={`${
          open
            ? "bottom-0 top-0 h-[830px]"
            : "bottom-[-30px] top-[110%] h-[0px] "
        } overflow-y-hidden  absolute left-0 right-0  z-20 duration-300`}
      >
        <SelectedCoin
          setOpen={setOpen}
          setRecive={setRecive}
          setPay={setPay}
          data={data}
        />
      </div>
      <div
        className={`${
          openProvider
            ? "bottom-0 top-0 h-[830px]"
            : "bottom-[-30px] top-[110%] h-[0px] "
        } overflow-y-hidden  absolute left-0 right-0  z-20 duration-300`}
      >
        <SelectProvider
          ApproveUsdt={ApproveUsdt}
          setOpenProvider={setOpenProvider}
          setProvider={setProvider}
        />
      </div>
    </div>
  );
};

export default Swap;
