import React from "react";
import { IoMdClose } from "react-icons/io";
const ChoseCurrency = ({ setOpen, setRecive, data, setPay, wich }) => {
  return (
    <div className="bg-[#222] h-full px-2">
      <div className="grid grid-cols-3 p-3">
        <div></div>
        <div className="flex justify-center text-lg whitespace-nowrap">
          Chose Curency
        </div>
        <button className="flex justify-end" onClick={() => setOpen(false)}>
          <IoMdClose size={25} />
        </button>
      </div>
      <div className="flex flex-col gap-1 mt-2">
        {data.map((e, idx) => (
          <button
            onClick={() => {
              if (wich === "pay") {
                setPay(e);
                setOpen(false)
              }else{
                setRecive(e);
                setOpen(false)
              }
            }}
            className="bg-[#444] flex items-center gap-2 justify-start p-2 rounded-md"
          >
            <img className="w-[35px] h-[35px]" src={e.src} alt="" />
            <span>{e.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChoseCurrency;
