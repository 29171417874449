export const coins = [
  { name: "BTC", src: "/BTC.png", id: 1 },
  { name: "USDT", src: "/USDT.png", id: 2 },
  { name: "ETH", src: "/ETH.png", id: 3 },
  { name: "SOL", src: "/SOL.png", id: 5 },
  { name: "DOG", src: "/DOG.png", id: 6 },
  { name: "SHIB", src: "/SHIB.png", id: 7 },
  { name: "BNB(BEP20)", src: "/BNB.png", id: 97 },
  { name: "BTCPAY(BEP20)", src: "/BTCPAY.png", id: 8 },
  { name: "CRDN(BEP20)", src: "/$CRDN.png", id: 9 },
  { name: "FORWARD(BEP20)", src: "/$FORWARD.png", id: 10 },
  { name: "FUR(BEP20)", src: "/$FUR.png", id: 11 },
  { name: "PEX(BEP20)", src: "/PEX.png", id: 189 },
  { name: "MANIA(BEP20)", src: "/MANIA.png", id: 12 },
  { name: "10SET(BEP20)", src: "/10SET.png", id: 13 },
  { name: "1ART(BEP20)", src: "/1ART.png", id: 14 },
  { name: "1INCH(BEP20)", src: "/1INCH.png", id: 15 },
  { name: "2049(BEP20)", src: "/2049.png", id: 16 },
  { name: "3TH(BEP20)", src: "/3TH.png", id: 17 },
  { name: "BNB(BEP2)", src: "/BNB.png", id: 103 },
  { name: "BTCPAY(BEP2)", src: "/BTCPAY.png", id: 18 },
  { name: "CRDN(BEP2)", src: "/$CRDN.png", id: 19 },
  { name: "FORWARD(BEP2)", src: "/$FORWARD.png", id: 20 },
  { name: "FUR(BEP2)", src: "/$FUR.png", id: 21 },
  { name: "MANIA(BEP2)", src: "/MANIA.png", id: 22 },
  { name: "10SET(BEP2)", src: "/10SET.png", id: 23 },
  { name: "1ART(BEP2)", src: "/1ART.png", id: 24 },
  { name: "1INCH(BEP2)", src: "/1INCH.png", id: 25 },
  { name: "2049(BEP2)", src: "/2049.png", id: 26 },
  { name: "3TH(BEP2)", src: "/3TH.png", id: 27 },
  { name: "ADS", src: "/$ADS.png", id: 28 },
  { name: "ANRX", src: "/$ANRX.png", id: 29 },
  { name: "0KN", src: "/0KN.png", id: 30 },
  { name: "LOOT", src: "/$LOOT.png", id: 31 },
  { name: "MUSIC", src: "/$MUSIC.png", id: 32 },
  { name: "PEPE", src: "/pepe.png", id: 33 },
  { name: "TRDL", src: "/TRDL.png", id: 34 },
  { name: "VAULT", src: "/vault.png", id: 35 },
  { name: "ZKP", src: "/$ZKP.png", id: 36 },
  { name: "(Wormhole)", src: "/SOL.png", id: 37 },
  { name: "BNB", src: "/BNB.png", id: 38 },
  { name: "FDUSD", src: "/FDUSD.png", id: 39 },
  { name: "USDT", src: "/USDT.png", id: 40 },
  { name: "GRAY", src: "/GARY.png", id: 41 },
  { name: "MEMES", src: "/$MEMES.png", id: 42 },
  { name: "MYRO", src: "/$MYRO.png", id: 43 },
  { name: "PEEP", src: "/$PEEP.png", id: 44 },
  { name: "WEN", src: "/wen.png", id: 45 },
  { name: "1SOL", src: "/$1SOL.png", id: 46 },
  { name: "AART", src: "/AART.jpeg", id: 47 },
  { name: "ABR", src: "/SOL.png", id: 48 },
  { name: "ACS", src: "/ACS.png", id: 49 },
  { name: "ADA", src: "/ADA.jpeg", id: 50 },
  { name: "2CRV", src: "/2CRV.png", id: 51 },
  { name: "AICODE", src: "/aicode.png", id: 52 },
  { name: "ALIEN", src: "/alien.png", id: 53 },
  { name: "ALP", src: "/alp.png", id: 54 },
  { name: "ALTD", src: "/ALTD.png", id: 55 },
  { name: "AMC", src: "/AMC.jpeg", id: 56 },
  { name: "ANKR", src: "/ANKR.png", id: 57 },
  { name: "APEX", src: "/apex.png", id: 58 },
  { name: "ARB", src: "/ARB.png", id: 59 },
  { name: "ARBI", src: "/ARBI.png", id: 60 },
  { name: "AAVE.e", src: "/AAVE.e.png", id: 61 },
  { name: "ACRE", src: "/ACRE.png", id: 62 },
  { name: "ALOT", src: "/ALOT.png", id: 63 },
  { name: "ALPHA.e", src: "/ALPHA.e.png", id: 64 },
  { name: "APIEN", src: "/APEIN.png", id: 65 },
  { name: "AVAI", src: "/AVAI.png", id: 66 },
  { name: "AVAX", src: "/AVAX.png", id: 67 },
  { name: "AVXT", src: "/AVXT.png", id: 68 },
  { name: "AXIAL", src: "/AXIAL.png", id: 69 },
  { name: "BINGO", src: "/BINGO.png", id: 70 },
  { name: "BCH", src: "/BCH.png", id: 71 },
  { name: "DASH", src: "/DASH.png", id: 72 },
  { name: "DGB", src: "/DGB.png", id: 73 },
  { name: "DOGE", src: "/DOG.png", id: 74 },
  { name: "LTC", src: "/LTC.png", id: 75 },
  { name: "QTUM", src: "/QTUM .png", id: 76 },
  { name: "ZEC", src: "/ZEC.png", id: 77 },
  { name: "ordi", src: "/ordi.png", id: 78 },
  { name: "DG", src: "/$DG.webp", id: 79 },
  { name: "KMC", src: "/KMC.png", id: 80 },
  { name: "MECHA", src: "/MECHA.png", id: 81 },
  { name: "HIKARU(BEP20)", src: "/Untitled-1111111.png", id: 4 },
  { name: "ZKP", src: "/$ZKP.png", id: 82 },
  { name: "1FLR", src: "/1FLR.png", id: 83 },
  { name: "1INCH", src: "/1INCH.png", id: 84 },
  { name: "4INT", src: "/4INT.png", id: 85 },
  { name: "AAVE", src: "/AAVE.png", id: 86 },
  { name: "ADDY", src: "/ADDY.png", id: 87 },
  { name: "ADF", src: "/ADF.png", id: 88 },
  { name: "1ART", src: "/1ART.png", id: 89 },
  { name: "20MB", src: "/2OMB.png", id: 90 },
  { name: "2SHARES", src: "/2shares.png", id: 91 },
  { name: "AAVE", src: "/AAVE.png", id: 92 },
  { name: "ALPACA", src: "/ALPACA.png", id: 93 },
  { name: "ANY", src: "/ANY.png", id: 94 },
  { name: "AVAX", src: "/AVAX.png", id: 95 },
  { name: "BEETS", src: "/BEETS.png", id: 96 },
  
  
  { name: "BOO", src: "/BOO.png", id: 98 },
  { name: "TRX", src: "/TRX.png", id: 99 },
  { name: "TUSD", src: "/TUSD.png", id: 100 },
  { name: "USDT", src: "/USDT.png", id: 101 },
  { name: "WIN", src: "/WIN.png", id: 102 },
];
export const Providers = [
  { name: "PancakeSwap", src: "/pancake.png", id: 1, min: 10 },
  { name: "Uniswap ", src: "/uniswap.png", id: 2, min: 4 },
  { name: "Biswap ", src: "/biswap.png", id: 3, min: 12 },
  { name: "Curve", src: "/2CRV.png", id: 4, min: 18 },
  { name: "SunSwap", src: "/sunswap.png", id: 5, min: 8 },
  { name: "Raydium", src: "/raudium.png", id: 6, min: 9 },
];
export const category = [
  {
    name: "BNB Smart Chain (BEP20)",
    coins: [
      { name: "BTCPAY(BEP20)", src: "/BTCPAY.png", id: 1 },
      { name: "CRDN(BEP20)", src: "/$CRDN.png", id: 2 },
      { name: "FORWARD(BEP20)", src: "/$FORWARD.png", id: 3 },
      { name: "FUR(BEP20)", src: "/$FUR.png", id: 4 },
      { name: "MANIA(BEP20)", src: "/MANIA.png", id: 5 },
      { name: "10SET(BEP20)", src: "/10SET.png", id: 6 },
      { name: "1ART(BEP20)", src: "/1ART.png", id: 7 },
      { name: "1INCH(BEP20)", src: "/1INCH.png", id: 8 },
      { name: "2049(BEP20)", src: "/2049.png", id: 9 },
      { name: "3TH(BEP20)", src: "/3TH.png", id: 10 },
      { name: "HIKARU(BEP20)", src: "/Untitled-1111111.png", id: 11 },
      { name: "PEX(BEP20)", src: "/PEX.png", id: 189 }
    ],
    id: 1,
  },
  {
    name: "BNB Smart Chain (BEP2)",
    coins: [
      { name: "BTCPAY(BEP2)", src: "/BTCPAY.png", id: 1 },
      { name: "CRDN(BEP2)", src: "/$CRDN.png", id: 2 },
      { name: "FORWARD(BEP2)", src: "/$FORWARD.png", id: 3 },
      { name: "FUR(BEP2)", src: "/$FUR.png", id: 4 },
      { name: "MANIA(BEP2)", src: "/MANIA.png", id: 5 },
      { name: "10SET(BEP2)", src: "/10SET.png", id: 6 },
      { name: "1ART(BEP2)", src: "/1ART.png", id: 7 },
      { name: "1INCH(BEP2)", src: "/1INCH.png", id: 8 },
      { name: "2049(BEP2)", src: "/2049.png", id: 9 },
      { name: "3TH(BEP2)", src: "/3TH.png", id: 10 },
    ],
    id: 1,
  },
  {
    name: "Ethereum",
    coins: [
      { name: "ADS", src: "/$ADS.png", id: 1 },
      { name: "ANRX", src: "/$ANRX.png", id: 2 },
      { name: "0KN", src: "/0KN.png", id: 3 },
      { name: "LOOT", src: "/$LOOT.png", id: 4 },
      { name: "MUSIC", src: "/$MUSIC.png", id: 5 },
      { name: "PEPE", src: "/pepe.png", id: 6 },
      { name: "TRDL", src: "/TRDL.png", id: 7 },
      { name: "VAULT", src: "/vault.png", id: 8 },
      { name: "ZKP", src: "/$ZKP.png", id: 9 },
      { name: "(Wormhole)", src: "/SOL.png", id: 10 },
    ],
    id: 2,
  },
  {
    name: "opBNB",
    coins: [
      { name: "BNB", src: "/BNB.png", id: 1 },
      { name: "FDUSD", src: "/FDUSD.png", id: 2 },
      { name: "USDT", src: "/USDT.png", id: 3 },
    ],
    id: 3,
  },
  {
    name: "Solana",
    coins: [
      { name: "GRAY", src: "/GARY.png", id: 1 },
      { name: "MEMES", src: "/$MEMES.png", id: 2 },
      { name: "MYRO", src: "/$MYRO.png", id: 3 },
      { name: "PEEP", src: "/$PEEP.png", id: 4 },
      { name: "WEN", src: "/wen.png", id: 5 },
      { name: "1SOL", src: "/$1SOL.png", id: 6 },
      { name: "AART", src: "/AART.jpeg", id: 7 },
      { name: "ABR", src: "/SOL.png", id: 8 },
      { name: "ACS", src: "/ACS.png", id: 9 },
      { name: "ADA", src: "/ADA.jpeg", id: 10 },
    ],
    id: 4,
  },
  {
    name: "Arbitrum",
    coins: [
      { name: "2CRV", src: "/2CRV.png", id: 1 },
      { name: "AICODE", src: "/aicode.png", id: 2 },
      { name: "ALIEN", src: "/alien.png", id: 3 },
      { name: "ALP", src: "/alp.png", id: 4 },
      { name: "ALTD", src: "/ALTD.png", id: 5 },
      { name: "AMC", src: "/AMC.jpeg", id: 6 },
      { name: "ANKR", src: "/ANKR.png", id: 7 },
      { name: "APEX", src: "/apex.png", id: 8 },
      { name: "ARB", src: "/ARB.png", id: 9 },
      { name: "ARBI", src: "/ARBI.png", id: 10 },
    ],
    id: 5,
  },
  {
    name: "Avalanche",
    coins: [
      { name: "AAVE.e", src: "/AAVE.e.png", id: 1 },
      { name: "ACRE", src: "/ACRE.png", id: 2 },
      { name: "ALOT", src: "/ALOT.png", id: 3 },
      { name: "ALPHA.e", src: "/ALPHA.e.png", id: 4 },
      { name: "APIEN", src: "/APEIN.png", id: 5 },
      { name: "AVAI", src: "/AVAI.png", id: 6 },
      { name: "AVAX", src: "/AVAX.png", id: 7 },
      { name: "AVXT", src: "/AVXT.png", id: 8 },
      { name: "AXIAL", src: "/AXIAL.png", id: 9 },
      { name: "BINGO", src: "/BINGO.png", id: 10 },
    ],
    id: 6,
  },
  {
    name: "Linea",
    coins: [{ name: "empty", src: "/safepal.png" }],
    id: 7,
  },
  {
    name: "Bitcoin",
    coins: [
      { name: "BCH", src: "/BCH.png", id: 1 },
      { name: "DASH", src: "/DASH.png", id: 2 },
      { name: "DGB", src: "/DGB.png", id: 3 },
      { name: "DOGE", src: "/DOG.png", id: 4 },
      { name: "LTC", src: "/LTC.png", id: 5 },
      { name: "QTUM", src: "/QTUM .png", id: 6 },
      { name: "ZEC", src: "/ZEC.png", id: 7 },
      { name: "ordi", src: "/ordi.png", id: 8 },
    ],
    id: 8,
  },
  {
    name: "Polygon",
    coins: [
      { name: "DG", src: "/$DG.webp", id: 1 },
      { name: "KMC", src: "/KMC.png", id: 2 },
      { name: "MECHA", src: "/MECHA.png", id: 3 },
      { name: "ZKP", src: "/$ZKP.png", id: 4 },
      { name: "1FLR", src: "/1FLR.png", id: 5 },
      { name: "1INCH", src: "/1INCH.png", id: 6 },
      { name: "4INT", src: "/4INT.png", id: 7 },
      { name: "AAVE", src: "/AAVE.png", id: 8 },
      { name: "ADDY", src: "/ADDY.png", id: 9 },
      { name: "ADF", src: "/ADF.png", id: 10 },
    ],
    id: 9,
  },
  {
    name: "Fantom",
    coins: [
      { name: "1ART", src: "/1ART.png", id: 1 },
      { name: "20MB", src: "/2OMB.png", id: 2 },
      { name: "2SHARES", src: "/2shares.png", id: 3 },
      { name: "AAVE", src: "/AAVE.png", id: 4 },
      { name: "ALPACA", src: "/ALPACA.png", id: 5 },
      { name: "ANY", src: "/ANY.png", id: 6 },
      { name: "AVAX", src: "/AVAX.png", id: 7 },
      { name: "BEETS", src: "/BEETS.png", id: 8 },
      { name: "BNB(BEP20)", src: "/BNB.png", id: 9 },
      { name: "BNB(BEP2)", src: "/BNB.png", id: 11 },
      { name: "BOO", src: "/BOO.png", id: 10 },
    ],
    id: 10,
  },
  {
    name: "Tron",
    coins: [
      { name: "TRX", src: "/TRX.png", id: 1 },
      { name: "TUSD", src: "/TUSD.png", id: 2 },
      { name: "USDT", src: "/USDT.png", id: 3 },
      { name: "WIN", src: "/WIN.png", id: 4 },
    ],
    id: 11,
  },
];
export const money = [
  { name: "USD", src: "/usd.png", id: 1 },
  { name: "JPY", src: "/jpy.png", id: 2 },
  { name: "CAD", src: "/cad.png", id: 3 },
  { name: "AUD", src: "/aud.png", id: 4 },
  { name: "EUR", src: "/eur.png", id: 5 },
  { name: "GBP", src: "/gbp.png", id: 6 },
  { name: "NZD", src: "/nzd.png", id: 7 },
];
