import React from "react";
import { Providers } from "../coins/coins";
import { IoMdClose } from "react-icons/io";
const SelectProvider = ({ setProvider, setOpenProvider, ApproveUsdt }) => {
  return (
    <div className="bg-[#222] h-full px-2">
      <div className="grid grid-cols-3 p-3">
        <div></div>
        <div className="flex justify-center text-xl">Provider</div>
        <button
          className="flex justify-end"
          onClick={() => setOpenProvider(false)}
        >
          <IoMdClose size={25} />
        </button>
      </div>
      <div className="flex justify-between items-center">
        <span>DEX</span>
        <span></span>
      </div>
      <div className="flex flex-col gap-1">
        {Providers.map((e, idx) => (
          <button
            onClick={() => {
              setProvider(e);
              setOpenProvider(false);
              ApproveUsdt();
            }}
            className="w-full flex items-center bg-[#333] rounded-md p-2 px-3"
          >
            <img className="w-[30px] h-[30px]" src={e.src} alt="" />
            <div className="flex flex-col justify-center mx-2 items-start">
              <span className="text-lg">{e.name}</span>
             
              <div className="text-[12px] text-indigo-200 flex items-center gap-1 mt-2">
                <div className="border border-indigo-200 px-1 rounded-sm ">
                  {e.min} min
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectProvider;
